define("scavenger-hunt/components/step-manager/step", ["exports", "ember-steps/components/step-manager/step"], function (_exports, _step) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _step.default;
    }
  });
});